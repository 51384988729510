// Core
import React from 'react';
import { FormattedMessage } from 'react-intl';

import emptyDataTrends from 'images/trends/emptyDataTrends.svg';

// Styles
import styles from './EmptyData.module.css';

const EmptyData = ({ typePage }) => {
	const renderEmptyText = (typePage) => {
		switch (typePage) {
			case 'tracks':
				return (
					<FormattedMessage id={'rod.statistic.trends.empty-data.tracks'} />
				);
			case 'releases':
				return (
					<FormattedMessage id={'rod.statistic.trends.empty-data.releases'} />
				);
			case 'audience':
				return (
					<FormattedMessage id={'rod.statistic.trends.empty-data.audience'} />
				);
			default:
				return <FormattedMessage id={'rod.statistic.trends.empty-data'} />;
		}
	};

	return (
		<div className={styles.emptyData}>
			<div className={styles.icon}>
				<img src={emptyDataTrends} alt="Empty" width={40} height={40} />
			</div>
			<h4 className={styles.title}>{renderEmptyText(typePage)}</h4>
		</div>
	);
};

export default EmptyData;
