import React, { useState, useContext, useEffect } from 'react';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { AuthContext } from 'contexts/AuthContext';
import { UIContext } from 'contexts/UIContext';

import Tabs from './Tabs/Tabs';
import ContentPage from './ContentPage/ContentPage';
import MetadataDetail from '../MetadataDetail/MetadataDetail';

import styles from './ArtistPage.module.css';

const ArtistPage = () => {
	const { accountId } = useContext(AuthContext);
	const { params } = useRouteMatch();
	const { pathname } = useLocation();
	const { showBackNavi, hideBackNavi, showTitle } = useContext(UIContext);
	const history = useHistory();
	const [tab, setTab] = useState('artist');

	const handleClickTab = (tab) => {
		switch (tab) {
			case 'artist':
				setTab('artist');
				history.push(
					`/accounts/${accountId}/statistic/trends/artist/${params.id}`
				);
				break;
			case 'tracks':
				setTab('tracks');
				history.push(
					`/accounts/${accountId}/statistic/trends/artist/${params.id}/tracks`
				);
				break;
			case 'releases':
				setTab('releases');
				history.push(
					`/accounts/${accountId}/statistic/trends/artist/${params.id}/releases`
				);
				break;
			case 'audience':
				setTab('audience');
				history.push(
					`/accounts/${accountId}/statistic/trends/artist/${params.id}/audience`
				);
				break;
			default:
				setTab('artist');
				history.push(
					`/accounts/${accountId}/statistic/trends/artist/${params.id}`
				);
		}
	};

	useEffect(() => {
		setTab(params.tab ?? 'artist');
		showTitle('');
		showBackNavi(
			'',
			() => {
				history.push(`/accounts/${accountId}/statistic/trends`);
			},
			null
		);
		return () => {
			hideBackNavi();
		};
	}, []);

	useEffect(() => {
		setTab(params.tab ?? 'artist');
	}, [pathname]);

	return (
		<div>
			<Tabs handleClickTab={handleClickTab} tab={tab} />
			<div className={styles.wrapperMetadata}>
				<MetadataDetail
					accountId={accountId}
					params={params}
					typePage="artist"
				/>
			</div>
			<ContentPage accountId={accountId} tab={tab} />
		</div>
	);
};

export default ArtistPage;
