import * as React from 'react';
import { useEffect, useState, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRoot, withAuth } from 'hocs';
import { compose } from 'recompose';
import debounce from 'lodash.debounce';
import isEqual from 'lodash/isEqual';
import { getJSONParse } from 'utils';

// UI
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Input, SelectCustom } from 'material-design/MuiStyled/MuiStyled';
import { CheckboxBlack } from 'material-design/MuiStyled/MuiStyled';
import { TextFieldInput } from 'material-design/MuiStyled/MuiStyled';
import FilterSkeleton from '../FilterSkeleton/index';
import { Label } from 'components/Labels';

// Styles
import s from '../TrendFilters.module.css';

const FilterRecordings = ({
	rootContext: { getAccountStatisticAllRecordings },
	dateFrom,
	dateTo,
	accountId,
	isAdmin,
	releaseIds,
	artists,
	recordingsState,
	lang,
	outlets,
	setResetRecordings,
	setIsGetRecordings,
	setIsResetPerformers,
	setIsResetReleases,
	isResetReleases,
	isResetPerformers,
	isLoadedReleases,
	isLoadedPerformers,
	performersIds,
}) => {
	const [recordings, setRecordings] = useState([]);
	const [checkedRecordingIds, setCheckedRecordingIds] = useState([]);
	const [cachedIds, setCachedIds] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [isShowSkeleton, setIsShowSkeleton] = useState(false);
	const [isEmptyData, setIsEmptyData] = useState(false);
	const [nameRecording, setNameRecording] = useState('');
	const [isFirstRender, setIsFirstRender] = useState(true);

	const [resetActive, setResetActive] = useState(false);

	const [cachedDateFrom, setCachedDateFrom] = useState('');
	const [cachedDateTo, setCachedDateTo] = useState('');
	const [cachedReleasesIds, setCachedReleasesIds] = useState([]);

	const [checkedRecordings, setCheckedRecordings] = useState([]);

	const [page] = useState(1);
	const [limit] = useState(50);

	const [isReqLive, setIsReqLive] = useState(false);

	const getRecordings = (releaseIds = []) => {
		setIsGetRecordings(false);
		setIsShowSkeleton(true);
		getAccountStatisticAllRecordings(
			isAdmin,
			accountId,
			searchValue,
			page,
			limit,
			dateFrom,
			dateTo,
			isResetPerformers || isResetReleases ? [] : releaseIds,
			[],
			outlets,
			performersIds
		).then((res) => {
			const data = res.data.data.map((item) => ({
				...item,
				checked:
					!isEqual(releaseIds, cachedReleasesIds) ||
					checkedRecordingIds.includes(item.heaven11_track_id) ||
					(releaseIds.length &&
						!searchValue.length &&
						!checkedRecordingIds.length)
						? true
						: false,
			}));

			const allRecordingsButton = {
				heaven11_track_id: 0,
				raw_title: lang === 'en' ? 'Select all' : 'Выбрать все',
				checked: searchValue.length > 0 ? false : true,
			};

			setCachedDateFrom(dateFrom);
			setCachedDateTo(dateTo);
			setCachedReleasesIds(releaseIds);

			let recordingsData;

			if (data.length) {
				recordingsData = [allRecordingsButton, ...data];
				setIsEmptyData(false);
			} else {
				recordingsData = [];
				setIsEmptyData(true);
			}

			if (dateFrom !== cachedDateFrom || dateTo !== cachedDateTo) {
				const newRecordings = recordingsData.map((item) => ({
					...item,
					checked: checkedRecordingIds.includes(item.heaven11_track_id),
				}));
				setCheckedRecordingIds(
					newRecordings
						.filter((item) => item.checked)
						?.map((item) => item.heaven11_track_id)
						.filter((id) => id !== 0)
				);
				setCheckedRecordings(
					newRecordings
						.filter((item) => item.checked && item.heaven11_track_id !== 0)
						?.map((item) => item)
				);

				recordingsState(
					newRecordings
						.filter((item) => item.checked)
						?.map((item) => item.heaven11_track_id)
				);
			} else if (
				!isEqual(releaseIds, cachedReleasesIds) ||
				(resetActive && releaseIds.length)
			) {
				const allRecordings = recordingsData.map(
					(item) => item.heaven11_track_id
				);
				setCheckedRecordingIds(allRecordings);
				setCheckedRecordings(
					recordingsData
						.filter((item) => item.heaven11_track_id !== 0)
						.map((item) => item)
				);
				recordingsState(allRecordings.filter((id) => id !== 0));
			}

			if (isResetPerformers || isResetReleases) {
				setCheckedRecordingIds([]);
				setCheckedRecordings([]);
				recordingsState([]);
				setNameRecording('');

				setSearchValue('');
			}

			setRecordings(recordingsData);
			setIsShowSkeleton(false);
			setIsGetRecordings(true);
		});
	};

	useEffect(() => {
		setIsReqLive(isLoadedReleases);
	}, [isLoadedPerformers, isLoadedReleases]);

	useEffect(() => {
		if (isFirstRender) {
			setIsFirstRender(false);
			return;
		}
		if (
			(searchValue.length || isResetPerformers || isResetReleases) &&
			isReqLive &&
			releaseIds.length <= 10
		) {
			getRecordings(releaseIds);
			setIsResetPerformers(false);
			setIsResetReleases(false);
		} else if (!isShowSkeleton && isReqLive && releaseIds.length <= 10) {
			getRecordings(releaseIds);
		}

		setResetActive(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [releaseIds, searchValue, isReqLive]);

	const handleCheckedRecording = (checkedRecording) => {
		setNameRecording(checkedRecording.raw_title);

		let mappedRecordings;

		if (checkedRecording.heaven11_track_id === 0 && checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: false,
			}));

			setCheckedRecordingIds([]);
			setCheckedRecordings([]);
		}

		if (checkedRecording.heaven11_track_id === 0 && !checkedRecording.checked) {
			mappedRecordings = recordings.map((release) => ({
				...release,
				checked: true,
			}));

			setCheckedRecordingIds(
				recordings.map((release) => release.heaven11_track_id)
			);
			setCheckedRecordings(recordings);
		}

		if (
			!(checkedRecording.heaven11_track_id === 0) &&
			checkedRecording.checked
		) {
			mappedRecordings = recordings.map((release) => {
				if (release.heaven11_track_id === 0) {
					return { ...release, checked: false };
				}

				if (release.heaven11_track_id === checkedRecording.heaven11_track_id) {
					return { ...release, checked: false };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds(
				checkedRecordingIds.filter(
					(id) => id !== checkedRecording.heaven11_track_id && id !== 0
				)
			);
			setCheckedRecordings(
				checkedRecordings.filter(
					(item) =>
						item.heaven11_track_id !== checkedRecording.heaven11_track_id &&
						item.heaven11_track_id !== 0
				)
			);
		}

		if (
			!(checkedRecording.heaven11_track_id === 0) &&
			!checkedRecording.checked
		) {
			mappedRecordings = recordings.map((release) => {
				if (release.heaven11_track_id === 0) {
					return { ...release, checked: false };
				}

				if (release.heaven11_track_id === checkedRecording.heaven11_track_id) {
					return { ...release, checked: true };
				} else {
					return release;
				}
			});

			setCheckedRecordingIds([
				...checkedRecordingIds.filter((checkedId) => checkedId !== 0),
				checkedRecording.heaven11_track_id,
			]);
			setCheckedRecordings([
				...checkedRecordings.filter((item) => item.heaven11_track_id !== 0),
				checkedRecording,
			]);
		}

		setRecordings(mappedRecordings);
	};

	const handleSearchInput = (e) => {
		setSearchValue(e.target.value);
	};

	const debouncedSearchHandler = useCallback(
		debounce(handleSearchInput, 900),
		[]
	);

	const handleClickOnly = (e, recording) => {
		setCheckedRecordings([recording]);
		e.stopPropagation();

		const mappedRecordings = recordings.map((item) => ({
			...item,
			checked: item.heaven11_track_id === recording.heaven11_track_id,
		}));

		setCheckedRecordingIds([recording.heaven11_track_id]);

		setRecordings(mappedRecordings);
	};

	const handleSelectClose = () => {
		if (cachedIds !== checkedRecordingIds) {
			recordingsState(checkedRecordingIds.filter((id) => id !== 0));
			setResetRecordings(false);
		}
	};

	const handleResetFilter = () => {
		setCheckedRecordingIds([]);
		setCheckedRecordings([]);
		recordingsState([]);
		setIsEmptyData(false);
		setNameRecording('');
		setResetActive(true);
		setSearchValue('');
	};

	const handleSelectOpen = () => {
		setCachedIds(checkedRecordingIds);
	};

	const valueForInput = () => {
		if (isShowSkeleton || !isLoadedPerformers) {
			return [0];
		} else if (!checkedRecordingIds.length) {
			return [];
		} else if (checkedRecordingIds.length) {
			return checkedRecordingIds;
		} else {
			return [];
		}
	};

	return (
		<FormControl variant="standard" className={s.formControl}>
			<Input id="tracks-select-label">
				<FormattedMessage id="rod.statistic.tracks" />
			</Input>

			<SelectCustom
				labelId="tracks-select-label"
				id="tracks-select"
				multiple
				value={valueForInput()}
				onOpen={handleSelectOpen}
				onClose={handleSelectClose}
				disabled={isShowSkeleton || !isLoadedReleases || !isLoadedPerformers}
				MenuProps={{
					style: { zIndex: 99999999999 },
				}}
				renderValue={() => {
					if (
						recordings.length === 1 &&
						recordings[0].heaven11_track_id === 0
					) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} /> 0{' '}
								<FormattedMessage id={'rod.statistic.selected-tracks'} />
							</>
						);
					}

					if (checkedRecordingIds.filter((id) => id !== 0).length === 1) {
						return (
							<span className={s.valueInputText}>
								{checkedRecordings[0]?.raw_title}
							</span>
						);
					} else if (
						isShowSkeleton ||
						!isLoadedReleases ||
						!isLoadedPerformers
					) {
						return <FormattedMessage id={'rod.statistic.updating-data'} />;
					} else if (!isShowSkeleton && recordings.length !== 0) {
						return (
							<>
								<FormattedMessage id={'rod.statistic.selected'} />{' '}
								{checkedRecordingIds.includes(0)
									? checkedRecordingIds.length - 1
									: checkedRecordingIds.length}{' '}
								<FormattedMessage id={'rod.statistic.selected-tracks'} />
							</>
						);
					}
				}}
			>
				<FormattedMessage id="rod.statistic.search-recordings">
					{(label) => (
						<TextFieldInput
							className={s.search}
							label={label}
							initialValue={searchValue ? searchValue : ''}
							defaultValue={searchValue}
							onChange={debouncedSearchHandler}
							variant="standard"
						/>
					)}
				</FormattedMessage>
				<div className={s.wrapper}>
					{!isShowSkeleton ? (
						recordings.map((recording, index) => (
							<MenuItem
								key={recording.heaven11_track_id}
								value={recording.raw_title}
								onClick={() => handleCheckedRecording(recording)}
							>
								<CheckboxBlack
									checked={checkedRecordingIds.includes(
										recording.heaven11_track_id
									)}
								/>
								<Label className={s.selectElement}>
									{recording.raw_title}{' '}
									{recording.heaven11_track_id !== 0
										? `- ${getJSONParse(recording.performers)}`
										: ''}
								</Label>
								<button
									className={s.btnOnly}
									onClick={(e) => handleClickOnly(e, recording)}
								>
									<FormattedMessage id={'rod.statistic.only'} />
								</button>
							</MenuItem>
						))
					) : (
						<FilterSkeleton />
					)}

					{!recordings.length && !isShowSkeleton && (
						<p className={s.noData}>
							<FormattedMessage id={'rod.no_data_select'} />
						</p>
					)}
				</div>
			</SelectCustom>

			{checkedRecordingIds.length > 50 && !checkedRecordingIds.includes(0) && (
				<p className={s.validateInput}>
					<FormattedMessage id={'rod.statistic.counter-recordings.error'} />
				</p>
			)}

			<button
				className={s.buttonResetFilter}
				onClick={handleResetFilter}
				style={
					!recordings.length && (!isShowSkeleton || !isLoadedPerformers)
						? { opacity: '0.4', pointerEvents: 'none' }
						: {}
				}
			>
				<FormattedMessage id={'rod.statistic.reset-filter'} />
			</button>
		</FormControl>
	);
};

export default compose(withRoot, withAuth)(FilterRecordings);
