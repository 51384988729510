// Core
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

// UI
import CloseIcon from '@mui/icons-material/Close';
import FilterPerformers from '../TrendsFilters/FilterPerformers/FilterPerformers';
import FilterReleases from '../TrendsFilters/FilterReleases/FilterReleases';
import FilterRecordings from '../TrendsFilters/FilterRecordings/FilterRecordings';
import FilterCountries from '../TrendsFilters/FilterCountries/FilterCountries';

import { Button } from 'components/Buttons';

// Styles
import styles from './StatisticFilterModal.module.css';

const StatisticFilterModalTrend = ({
	active,
	setActive,
	isAdmin,
	accountId,
	lang,
	setLoading,
	startEndDates,
	setSideBarFiltersData,
}) => {
	const [filtersData, setFiltersData] = useState({
		tracksIds: [],
		outlets: [],
		performers: [],
		countries: [],
	});

	const [isInitial, setIsInitial] = useState(false);
	const [performers, setPerformers] = useState([]);
	const [performersIds, setPerformersIds] = useState([]);
	const [releases, setReleases] = useState([]);
	const [recordings, setRecordings] = useState([]);
	const [countries, setCountries] = useState([]);
	const [countriesInFilter, setCountriesInFilter] = useState([]);
	const [updatekey, setUpdateKey] = useState(Math.random() * 100);
	const [disabled, setDisabled] = useState(false);
	const [resetRecordings, setResetRecordings] = useState(false);

	const [isGetReleases, setIsGetReleases] = useState(true);
	const [isGetRecordings, setIsGetRecordings] = useState(true);

	const [isResetPerformers, setIsResetPerformers] = useState(false);
	const [isResetReleases, setIsResetReleases] = useState(false);

	const [noCountries, setNoCountries] = useState(false);

	const [isLoadedPerformers, setIsLoadedPerformers] = useState(false);
	const [isLoadedReleases, setIsLoadedReleases] = useState(false);
	const [isLoadedRecordings, setIsLoadedRecordings] = useState(false);

	useEffect(() => {
		if (active) {
			document.body.style.position = 'fixed';
			document.body.style.top = `-${window.scrollY}px`;
		}
		if (!active) {
			const scrollY = document.body.style.top;
			document.body.style.position = '';
			document.body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		}
	}, [active]);

	useEffect(() => {
		if (
			isInitial &&
			recordings.length &&
			performers.length &&
			releases.length
		) {
			setIsInitial(false);
		}
	}, [isInitial, recordings, performers, releases]);

	useEffect(() => {
		if (!recordings.length) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}
	}, [performers, releases, recordings, countriesInFilter]);

	const handleApplyAllFilters = () => {
		const countriesCode = countriesInFilter.map((item) => item.code);
		setSideBarFiltersData({
			performers: performers.length ? performers : [],
			releases: releases.length ? releases : [],
			recordings: resetRecordings ? [] : recordings,
			countries: countriesCode,
			artistIds: performersIds.length ? performersIds : [],
		});
		setActive(false);
	};

	const handleClearAll = () => {
		// handleResetAllFilters();
		setFiltersData({
			tracksIds: [],
			releases: [],
			performers: [],
			countries: [],
		});
		setSideBarFiltersData({
			performers: [],
			releases: [],
			recordings: [],
			countries: [],
			artistIds: [],
		});
		setPerformers([]);
		setPerformersIds([]);
		setReleases([]);
		setRecordings([]);
		setCountriesInFilter([]);
		setUpdateKey(Math.random() * 100);
		setActive(false);
	};

	const handleCheckedCountry = (checkedCountries, isAllChecked) => {
		if (checkedCountries || isAllChecked) {
			setNoCountries(false);
		}

		if (!checkedCountries) {
			return;
		}

		if (isInitial) {
			setCountries(checkedCountries);
			return;
		}

		filtersData.countries = isAllChecked ? [] : checkedCountries;

		setFiltersData({ ...filtersData });
		const countriesCode = filtersData.countries.map((item) => item.code);
		setCountriesInFilter(countriesCode);

		if (!isAllChecked && !filtersData.countries.length) {
			setDisabled(true);
			setNoCountries(true);
		} else {
			setNoCountries(false);
		}
	};

	return (
		<>
			<div
				className={
					active ? `${styles.Overlay} ${styles.OverlayActive}` : styles.Overlay
				}
			/>
			<div
				id="wrapper-overlay"
				className={active ? `${styles.modal} ${styles.active}` : styles.modal}
				onClick={() => setActive(false)}
			>
				<div
					id="wrapper-drawer"
					key={updatekey}
					className={styles.container}
					onClick={(e) => e.stopPropagation()}
				>
					<div className={styles.modalHeader}>
						<div className={styles.modalHeader__titleWrapper}>
							<span className={styles.modalHeader__title}>
								<FormattedMessage id={'rod.release.info.filter'} />
							</span>
							<span
								onClick={handleClearAll}
								className={styles.modalHeader__clear}
							>
								<FormattedMessage id={'rod.repertoire.clear_all'} />
							</span>
							<div className={styles.modalHeader__icon}>
								<CloseIcon
									onClick={() => setActive(false)}
									style={{ cursor: 'pointer' }}
								/>
							</div>
						</div>
					</div>
					<div className={styles.modalBody}>
						<div className={styles.setting}>
							<div className={styles.inputTrack}>
								<FilterPerformers
									isAdmin={isAdmin}
									accountId={accountId}
									className={styles.tracksCheckmarks}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									setLoading={setLoading}
									setPerformersState={setPerformers}
									setIsResetPerformers={setIsResetPerformers}
									lang={lang}
									setIsLoadedPerformers={setIsLoadedPerformers}
									isLoadedPerformers={isLoadedPerformers}
									setPerformersIds={setPerformersIds}
								/>
							</div>
							<div className={styles.inputTrack}>
								<FilterReleases
									isAdmin={isAdmin}
									accountId={accountId}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									setLoading={setLoading}
									artists={performers}
									performersIds={performersIds}
									releasesState={setReleases}
									lang={lang}
									setResetRecordings={setResetRecordings}
									setIsResetPerformers={setIsResetPerformers}
									isResetPerformers={isResetPerformers}
									setIsResetReleases={setIsResetReleases}
									setIsGetReleases={setIsGetReleases}
									setIsLoadedPerformers={setIsLoadedPerformers}
									isLoadedPerformers={isLoadedPerformers}
									setIsLoadedReleases={setIsLoadedReleases}
								/>
							</div>
							<div className={styles.inputTrack}>
								<FilterRecordings
									isAdmin={isAdmin}
									accountId={accountId}
									className={styles.tracksCheckmarks}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									setLoading={setLoading}
									releaseIds={releases}
									recordingsState={setRecordings}
									lang={lang}
									setResetRecordings={setResetRecordings}
									setIsGetRecordings={setIsGetRecordings}
									setIsResetPerformers={setIsResetPerformers}
									isResetPerformers={isResetPerformers}
									setIsResetReleases={setIsResetReleases}
									isResetReleases={isResetReleases}
									isLoadedPerformers={isLoadedPerformers}
									isLoadedReleases={isLoadedReleases}
									performersIds={performersIds}
								/>
							</div>
							<div className={styles.inputTrack}>
								<FilterCountries
									accountId={accountId}
									handleCheckedCountries={handleCheckedCountry}
									dateFrom={startEndDates[0]}
									dateTo={startEndDates[1]}
									outlets={filtersData.outlets}
									lang={lang}
									tracksIds={recordings}
									setCountriesInFilter={setCountriesInFilter}
								/>
							</div>
						</div>
						<Button
							className={styles.submit_button}
							variant="primary"
							onClick={() => handleApplyAllFilters()}
							disabled={disabled}
						>
							<FormattedMessage id={'rod.release.info.apply'} />
						</Button>
					</div>
				</div>
			</div>
		</>
	);
};

export default StatisticFilterModalTrend;
